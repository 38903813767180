@use "../../style/variables.scss" as *;

.authentication {
    height: 400px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
   
    p {
        padding: 0.5rem 0;
        font-size: 1.2rem;
    }

    form {
        height: 150px;

        input {
            margin-bottom: .5rem;
        }
    }

    #signInDiv {
        width: fit-content;
        height: 100px;
    }

    .toggle {
        height: fit-content;
        display: flex;
        justify-content: center;
        .btn-container {
            width: fit-content;
        }
    }
}

.dark {
    .authentication {

    .toggle {
        button{
            border: 2px solid $light-gray;
        }
    }
}

}