@use "../../style/variables.scss" as *;

.modal {
    .drag-and-drop {
        text-align: center;
        height: 200px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-size: 24px;
        border: none;
        background-color: $light-gray;
        .drop-icon {
            font-size: 4rem;
        }
        p {
            font-size: 1rem;
        }
    }
}

.file-input {
    display: none;
}

.dark {

    .drag-and-drop {
        border: 1px $light-gray solid;
        background-color: $dark-gray;
        
    
}
}