// fonts
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

$logo-font: 'Major Mono Display', monospace;
$main-font: 'Poppins', sans-serif;
$extra-font: 'Space Mono', monospace;

// colors
$white: white;
$light-gray: #f9f8f8;
$dark-gray: #232323;
$less-dark-gray: #3d3d3d;
$blue: #70ACFE;
$yellow: #FFE927;
$pink: #FFB1E4;
$dark-pink: #AA336A;
$lavender: #A479D8;
$dark-lavender: #2f233f;
$turquoise: #00B0BD;

$max-screen: 960px;
$max-screen-mobile: 600px;
