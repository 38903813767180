@use "../../style/variables.scss" as *;

.favorite-main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding-top: 5rem;
}

.favorite-page-headline {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 3px;
    padding-bottom: 3.5rem;
}

.favorite-container {
    width: 800px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.favorite-track-line {
    background-color: white;
    border: 3px solid $dark-gray;
    height: 100px;
    width: 680px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;
    &:hover {
        box-shadow: 10px 10px 55px $pink;
    }
}

.favorite-track-line-flex-container {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
}

.favorite-play-btn {
    display: flex;
    font-size: 2rem;
    padding: 0 1.5rem 0 2rem; 

    svg:hover {
        * {
            stroke: $pink;
        }
    }
}

.favorite-profile-pic {
    width: 60px;
    height: 60px;
    img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.favorite-track-title {
    padding-right: 1rem;
    // color: $dark-gray;
    text-align: center;
}

.favorite-track-dash {
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.favorite-track-artist-name {
    padding-right: 1rem;
    text-align: center;

    &:hover {
        text-decoration: underline;
    }
}

.favorite-track-duration {
    // color: $dark-gray;
    padding-right: 1rem;
}

.favorite-track-like-btn {
    font-size: 1.2rem;
    margin-top: 0.3em;
    padding-right: 2.5rem;
    // &:hover {
    //     color: rgb(196, 196, 196);
    // }
}

.dark {
    .favorite-track-line {
        background-color: $dark-lavender;
        border: 3px solid $light-gray;
        &:hover {
            box-shadow: 10px 10px 55px $lavender;
        }
    }

    .favorite-track-artist-name {
    color: $light-gray;
}

    .favorite-play-btn {
        * {
        stroke: $light-gray;
        fill: $dark-lavender;

        &:hover {
            *{
                stroke: $dark-pink;
            }
        }
    }
    }
}

@media screen and (max-width: $max-screen) {

    .favorite-container {
        width: 100vw;
        margin: 0 auto;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        padding: 0 0.5rem;
    }

    .favorite-track-line {
        min-width: calc(320px - 1rem);
        max-width: calc(100vw - 1rem);
        // min-width: 320px;
        // max-width: 100vw;
    }

    .favorite-track-line-flex-container {
        display: flex;
        flex-direction: column;
    }

    .favorite-track-dash {
        visibility: hidden;
        margin: -0.4rem;
    }

    .favorite-track-like-btn {
        padding-right: 2rem;
    }
}

@media screen and (max-width: 380px) {
    .favorite-profile-pic {
        display: none;
    }
}