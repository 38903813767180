@use "../../style/variables.scss" as *;

.search-background {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
}

.search-bar-container {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.search-bar{
    width: 100%;
    height: 200px;
    padding: 2rem 8rem;
    background-color: rgba($pink, 0.6);
    animation: slide-up 1s ease-in-out;
    // border: 5px dashed yellow;

    label {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        text-shadow:2px 2px 3px rgb(255, 255, 255);
    }

    input {
        width: 50%;
    }
}

.dark {
    color: $light-gray;    
    
    .search-bar{
    
    background-color: rgba($dark-pink, 0.9);
    label {
        text-shadow: none;
    }
    input[type=text] {
        background-color: $dark-gray;    
        border-color: $light-gray;
        color: $light-gray;

        &::placeholder{
            color: $light-gray;
        }
    }
}
}

@keyframes slide-up {
    0% {transform: translateY(200px)}
    100% {transform: translateY(0)} 
}


@media screen and (max-width: $max-screen) {
    .search-bar {
        padding: 2rem 1rem 0 1rem;

        input {
            min-width: 90%;
        }
    }
}