@use './variables.scss' as *;
@use '../components/UI/alerts/alert.scss';
@use '../components/navbar/navbar.scss';
@use '../components/home/home.scss';
@use '../components/upload/upload.scss';
@use '../components/upload/fileUploader.scss';
@use '../components/UI/forms/form.scss';
@use '../components/authentication/authentication.scss';
@use '../components/authentication/authentication-forms.scss';
@use '../components/UI/button/button.scss';
@use '../components/UI/CardList/cardList.scss';
@use '../components/UI/Card/card.scss';
@use '../components/profile/profile.scss';
@use '../components/UI/modal/modal.scss';
@use '../components/profile/profile_update.styles.scss';
@use '../components/profile/pic-update.scss';
@use '../components/search/search.scss';
@use '../components/favoriteSongs/favorite.scss';
@use '../components/chat/chat.scss';

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //test
    color: $dark-gray;
    //test
  
    h1, h2, h3, h4, p, span, a {
      font-family: $main-font;
    }
    
    a {
      color: $dark-gray;
    }

    .details {
      font-family: $extra-font;
    }
  
    .App {display: flex;}

    .main {
      width: calc(100vw - 4.5rem);
      // height: fit-content;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      // dark-mode-test
      // background-color: $dark-gray;
    }
    .font-size-s {
      font-size: 1rem;
   
    }
    .font-size-xs {
      font-size: .8rem;
      
    }

    .icon-btn {
      cursor: pointer;
    }
}

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

.dark {
  
     .main {
       background-color: $dark-gray;
     }
 
}

@media screen and (max-width: $max-screen) {
  .App {
    width: 100vw;
    flex-direction: column;
    justify-content: center;
  }
  .main {
    width: 100vw!important;
    min-height: calc(100vh - 4.5rem)!important;
    // min-height: 0!important;
  }
}