@use '../../../style/variables.scss' as *;

.card {
    background-color: white;
    border: 2px solid $dark-gray;
    width: 256px;
    height: 256px;
    display: flex;
    margin: 1rem;
    // padding: 0;
}

.card-left-column {
    // background-color: aqua;
    width: 20%;
    height: 100%;
    display: flex;
}


.card-artist-name {
    // background-color: cadetblue;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    padding: 1.35rem 0.6rem 1.35rem 0.6rem;
    font-family: $main-font;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 2px;
    word-wrap: break-word;
}

.card-right-column {
    // background-color: lightsalmon;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
}

.card-track-pic {
    background-color: $light-gray;
    width: 150px;
    height: 150px;
    margin-top: 15%;
    margin-bottom: 3%;
    img {
        max-width: 100%;
        height: 100%;
    }
}

.card-bottom-column {
    width: 100%;
    height: 28%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.play-btn {
    font-size: 2rem;
   
    svg:hover {
        * {
            stroke: $pink;
        }
    }
}

// .play-btn {
//     * {
//         stroke: $light-gray;
//         fill: $dark-gray;
//     }
//     &:hover {
//         *{
//             stroke: $dark-pink;
//         }
//     }
// }



.card-track-title {
    // background-color: chartreuse;
}

.dark {
    .play-btn {
        svg:hover {
        * {
            stroke: $dark-pink;
        }
    }
    }
}

@media screen and (max-width: $max-screen) {
    .card {
        width: 240px;
        height: 240px;
        margin-bottom: 1rem;
    }
  }