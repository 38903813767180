@use "../../../style/variables.scss" as *;

.btn-container {
    height: 100%;
}

button {
    cursor: pointer;
    color: $dark-gray;
    margin-top: 1rem;
    background-color: white;
    font-size: 1rem;
    font-family: $main-font;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.5em;
    margin: 1rem 0;
    border: none;
    box-shadow: inset 0 0 0 2px $dark-gray;
}

.send {
    margin: 0;
    height: 100%;
    background: linear-gradient(141deg, rgba(35,35,35,1) 31%, rgba(164,121,216,1) 72%), left top;
    background-size: 350% 300%;
    color: white;
    box-shadow: none;
    transition: all 1s ease;
    &:hover {
        background-position: right bottom;
    }
}

.submit {
    height: fit-content;
    display: block;
    height: 40px;

@mixin btn-border-drawing($color: $dark-gray, $hover: $lavender, $width: 4px, $vertical: top, $horizontal: left, $duration: 0.2s) {
    box-shadow: inset 0 0 0 $width $dark-gray;

    transition: color $duration $duration/3;
    position: relative;
    
    &::before,
    &::after {
        border: 0 solid transparent;
        box-sizing: border-box;
        content: '';
        pointer-events: none;
        position: absolute;
        width: 0; height: 0;
        
        #{$vertical}: 0; 
        #{$horizontal}: 0;
    }
    
    &::before {
        $h-side: if($horizontal == 'left', 'right', 'left');
        
        border-#{$vertical}-width: $width;
        border-#{$h-side}-width: $width;
    }
    
    &::after {
        $v-side: if($vertical == 'top', 'bottom', 'top');
        
        border-#{$v-side}-width: $width;
        border-#{$horizontal}-width: $width;
    }
    
    &:hover {
        color: $lavender;
        
        &::before,
        &::after {
            border-color: $color;
            transition: border-color 0s, width $duration, height $duration;
            width: 100%;
            height: 100%;
        }
      
      &::before { transition-delay: 0s, 0s, $duration; }
      
      &::after { transition-delay: 0s, $duration, 0s; }
    }
}

&{
    @include btn-border-drawing($lavender, $lavender, 4px, bottom, right);
}}

.icon {
    cursor: pointer;
}

.btn-small {
    font-size: .8rem;
}

.dark {
    button {
        color: $light-gray;
        background-color: $dark-gray;
    }

    .submit {
        display: block;

        @mixin btn-border-drawing($color: $light-gray, $hover: $lavender, $width: 4px, $vertical: top, $horizontal: left, $duration: 0.2s) {
            box-shadow: inset 0 0 0 $width $light-gray;
        }
        &{
            @include btn-border-drawing($lavender, $lavender, 4px, bottom, right);
        }
    }

}