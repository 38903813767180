@use "../../style/variables.scss" as *;

.track-upload-form {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    input {
        margin-bottom: 1rem;
    }
    .animation-container {
        padding: 3.5rem 1rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        span {
            font-size: .9rem;
            margin: .5rem auto;
            text-align: center;
        }
    }
}

