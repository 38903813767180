@use '../../../style/variables.scss' as *;

form {
    outline: none;
    input {
        // margin-bottom: .5rem;
        border: solid 2px $dark-gray;
        padding: 0.5em;
        font-size: 1rem;
        font-family: $main-font;
        font-weight: 600;
        width: 100%;
        outline:none;
        &[type=text]:focus, &[type=email]:focus, &[type=password]:focus,  &[type=fieldset] {
            border: 2px solid $pink;
        }
        &[type=submit] {
            text-transform: uppercase;
            &:hover {
                background-color: $pink;
            }
        }
        &::placeholder {
            color: $dark-gray;
            font-weight: 200;
            letter-spacing: 2px;
        }
    }
    textarea {
        border: solid 2px $dark-gray;
    }

    textarea:focus-visible {
        border: 2px solid $pink;
        outline: none;
    }

    input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active,
        input:-internal-autofill-selected,
        input:-internal-autofill-previewed {
            -webkit-text-fill-color: $dark-gray;
            -webkit-box-shadow: 0 0 0 40px white inset !important;
            
        }

    .drag-and-drop {
        cursor: pointer;
    }
    button {
        height: 100%;
    }
    .btn-small {
        background-color: $dark-gray;
        color: white;
        margin: 0;
        &:hover{
            color: $lavender;
            &:active{
                color: $pink;
            }
        }
    }
}

.dark {
    form{
        input {
            background-color: $dark-gray;
            color: $light-gray!important;
            border: 2px solid $light-gray;
            &::placeholder {
                color: $light-gray;
            }
            &:-webkit-autofill {
            
            background-color: $dark-gray;
            -webkit-box-shadow: 0 0 0 50px white inset;
            }
            &:focus,  &[type=fieldset] {
            border: 2px solid $dark-pink;
            color: $light-gray;
            }
        }
        textarea:focus-visible {
            border: 2px solid $dark-pink;
            outline: none;
        }
        


        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active,
        input:-internal-autofill-selected,
        input:-internal-autofill-previewed {
           
            -webkit-text-fill-color: $light-gray;
            -webkit-box-shadow: 0 0 0 40px $dark-gray inset !important;
        }
        
        .btn-small {
        background-color: $light-gray;
        color: $dark-gray;
        // border: 2px solid $light-gray;
        border-color: $light-gray;
        margin: 0;
        &:hover{
            color: $pink;
            &:active{
                color: $lavender; 
            }
        }
    }
    }
}
