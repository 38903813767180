@use "../../style/variables.scss" as *;

.home {
    // background-image: url('../../img/bg-light-home.png');
    background-image: url('../../img/bg-home-pink.png');
    // background-image: url('../../img/bg-light-home-lighter.png');
    background-size: cover;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .home-container {
        width: 85%;
        height: 80%;
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
    }

    .headline {
        margin-top: 6rem;
    }

    h1 {
        display: inline;
        font-weight: 500;
        font-size: 5rem;
        line-height: 6rem;
    }

    h2 {
        font-weight: 200;
        font-size: 3.5rem;
        line-height: 4.5rem;
        padding: 2rem 0;
    }

    h4 {
        font-weight: 200;
        font-size: 1.5rem;
    }
}

.dark {
    
    .card {
        border: 2px solid $light-gray;
        color: $light-gray;
        background-color: $dark-gray;

        * {
        stroke: $light-gray;
        fill: $dark-gray;
    }
    }
}

@media screen and (max-width: $max-screen) {

    .home .home-container {

        width: 100%;
        min-height: calc(100vh - 4.5rem);
        padding: 1rem;

        .headline {
            margin-top: 1rem;
            h1 {
                font-size: 3rem;
                line-height: 4rem;
            }
        
            h2 {
                font-size: 2rem;
                line-height: 2.5rem;
                padding: 1.5rem;
            }
        }

        h4 {
            font-size: 1.2rem;
        }

        .login-btn-container {
            margin: 0 1.5rem
        }
    }

    .home-container {
        justify-content: space-evenly;
    }
  }