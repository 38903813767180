@use '../../style/variables.scss' as *;


::-webkit-scrollbar {
            width: 6px;
            background-color: $light-gray;
        }
        ::-webkit-scrollbar-track {
            width: 2px;
        }
        ::-webkit-scrollbar-thumb {
            background: $dark-gray;
            border-radius: 10px;
        }
    

.dropdown-menu {
    height: 400px!important;
    width: 200px!important;
    overflow: auto;
    border: 2px solid $dark-gray;
    position: absolute;
    top: 5rem;
    background-color: $light-gray;
    z-index: 4;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    input {
        display: none;
    }
    label {
        line-height: 2rem;
    }
    .checked {
            content: "";
            position: relative;
            border-color: $lavender;
            border-style: solid;
            border-width: 0 0.3em 0.25em 0;
            height: 1em;
            top: .2em;
            left: 0;
            margin-top: -1em;
            transform: rotate(45deg);
            width: 0.5em;
    }
    .styled-checkbox {
        width: 0.5em;
        height: 1em;
    }
    .dropdown-list-item {
        display: flex;
        width: 90%;
        justify-content: space-between;
    }

        
}

.dropdown-background {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// ------------------DARK MODE-------------------

.dark {

    .updateForm {
        input[type=text]{
            background-color: $dark-gray;
            border-color: $light-gray;
            &::placeholder{
                color: $light-gray;
            }
        }
        ::-webkit-scrollbar {
        background: $dark-gray;
        }
        ::-webkit-scrollbar-thumb {
            background: $light-gray;
        }
        }

    .dropdown-menu {
    border-color: $light-gray;
    background-color: $dark-gray;
    }

    .profile-info-edit-btn button.submit {
        border: none;
    }

    .modal {
        background-color: $dark-lavender;
        input {
            // background: $light-gray;
            &::placeholder {
                color: $light-gray;
            }
        }
    }

}

@media screen and (max-width: $max-screen-mobile) {

}