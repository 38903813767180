@use '../../../style/variables.scss' as *;


.card-list {
    // background-color: antiquewhite;
    width: 100%;
    display: flex;
    justify-content: center;
    // margin: auto;
    // padding: 1rem;
    flex-wrap: wrap;
}

@media screen and (max-width: $max-screen) {
    .card-list {
        margin: 1rem;
        padding: 1rem;
    }
  }

@media screen and (max-width: $max-screen-mobile) {
    .card-list {
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
    }
}   
