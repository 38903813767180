@use "../../style/variables.scss" as *;

.loginForm, .signupForm {
    width: 100%;
    height: 200px;
}

@media screen and (max-width: $max-screen) {
    .loginForm, .signupForm {
        width: 100%;
    }
}