@use "../../style/variables.scss" as *;

.chat-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding-top: 12rem;
    overflow: hidden;
}

.chat-header {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 3px;
    padding-bottom: 3.5rem;
}

.chat-container {
    box-shadow: 10px 10px 55px $pink;
    width: 800px;
    height: 600px;
    display: flex;
}

.chat-list-left {
    width: 300px;
    padding: 0 0.5em;
    border-right: dashed $dark-gray 2px;
    
    // new
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh - 4.5rem);
}

.chat-partner-row {
    border-bottom: 2px dotted $dark-gray;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.7rem;

    .chat-profile-img {
        img {
            background-color: $blue;
            height: 60px;
            width: 60px;
            border-radius: 50%;
        }
    }

    .chat-text-box {
        margin-left: 0.7rem;
        font-size: 0.8rem;
    }
    
    .chat-partner-name {
        font-weight: 600;
        margin: 0.3rem 0;
        .dot {
            margin-left: 1rem;
            height: 10px;
            width: 10px;
            background-color: $pink;
            border-radius: 50%;
            display: inline-block;
        }
    }

    .chat-text-teaser {
        margin: 0.3rem 0;
        height: 15px;
        width: 190px;
        overflow: hidden;
    }
}

.chat-window-right {
    background-color: white;
    width: 500px;
    height: 100%;
    // position: relative;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
        width: 8px;
        background: white;
    }
    ::-webkit-scrollbar-track {
        // width: 2px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $dark-gray;
    }
    ::-webkit-scrollbar-thumb:hover {
    }

    .chat-scroll {
        overflow-y: scroll;
        flex-grow: 2;
    }

    .current-chat-message-own, .current-chat-message-partner {
        border: 3px solid $dark-gray;
        margin: 1rem;
        padding: 0.8em;
        font-size: 0.9rem;
        line-height: 1.2rem;

        // new
        overflow-y: auto;
        height: auto;
        word-wrap: break-word;
    }

    .current-chat-message-own {
        border-radius: 13px 15px 0px 15px;
        margin-left: 2rem;
        background-color: rgb(235, 235, 235);
    }

    .current-chat-message-partner {
        border-radius: 13px 15px 15px 0px;
        margin-right: 2rem;
    }

    .current-chat-input {
        height: 100px;
        width: 100%;
        height: 200px;
        padding: .75rem;
        font-size: 0.9rem;
        line-height: 0.9rem;
        text-align: start;
        display: flex;
        align-items: center;
        textarea {
            resize: none;
            padding: .5rem;
            line-height: 1.2rem;
            min-height: 100%;
            width: 95%;
            overflow-y: scroll;
            border-radius: 0;
            white-space: pre-wrap;
           
        }
        textarea::-webkit-scrollbar {
            display: none;
        }

        button {
            padding: 1em;
        }
        // .send {
        //     height: 100%;
        //     background: linear-gradient(141deg, rgba(35,35,35,1) 31%, rgba(164,121,216,1) 72%), left top;
        //     background-size: 350% 300%;
        //     color: white;
        //     box-shadow: none;
        //     transition: all 1s ease;
        //     &:hover {
        //         background-position: right bottom;
        //     }
        // }

    }
}



.back-to-chat-partners-btn {
    display: none;
    border: 3px solid $dark-gray;
    background-color: white;
    margin: 1rem;
    padding: 0.2em;
    &:hover {
        color: $lavender;
        border: 3px solid $lavender;
    }
}



//-----------------------------DARK MODE---------------------------------

.dark {

.chat-container {
    box-shadow: 10px 10px 55px $lavender;
    background-color: $dark-gray;
}

.chat-list-left {
    background-color: $dark-lavender;
    border-right: dashed $light-gray 2px;
}

.chat-partner-row {
    border-bottom: 2px dotted $light-gray;
}

.chat-window-right {
    background-color: $dark-lavender;
    
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background-color: $dark-lavender;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $lavender;
    }
    ::-webkit-scrollbar-thumb:hover {
    }

    .current-chat-message-own, .current-chat-message-partner {
        border: 3px solid $dark-gray;     
    }

    .current-chat-message-own {
        border-color: $lavender;
        background-color: $dark-lavender;
    }
    
    .current-chat-message-partner {
        border-color: $lavender;
        background-color: $dark-gray;
    }

    .current-chat-input {

        textarea {
            background-color: $dark-gray;
            color: $light-gray;
            border: 2px $lavender solid;
            &::placeholder {
                color: $light-gray;
            }
        }
        textarea:focus-visible {
            border: 2px solid $dark-pink;
            outline: none;
        }
        
        .send {
            background: linear-gradient(141deg, rgba(164,121,216,1) 31%, rgba(170,51,106,1) 72%), left top;
            background-size: 300% 350%;
            &:hover {
                background-position: right bottom;
            }
        }
    
    }
}

.back-to-chat-partners-btn {
    border: 3px solid $light-gray;
    background-color: $dark-lavender;
    &:hover {
        border: 3px solid $lavender;
        color: $lavender;
    }
}

}

@media screen and (max-width: $max-screen) {
    .chat-main {
        padding-top: 0rem;
        height: calc(100vh - 4.5rem)!important;
    }

    .overlay {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        z-index: 2;
        position: absolute;
        top: 4.5rem;

        .btn-container {
            margin: 0;
            // height: 4.5rem;
            text-align: center;
            .back-to-chat-partners-btn {
                box-shadow: none;
            }
        }
    }

    .overlay-hidden {
        display: none;
    }
    
    .chat-container {
        background-color: white;
        box-shadow: none;
        width: 100%;
        height: 100%;
        // display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        
        .chat-list-left, .chat-window-right {
            height: calc(100vh - 9rem);
            width: 100%;
            max-width: 500px;
            border: none;
        }

         .chat-window-right {
           padding: 0.5rem;
           display: flex;
           flex-direction: column;
        //    align-items: flex-start;
         }

        .chat-list-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .current-chat-input {
            height: 100px;
            padding: .5rem 0 0 0;
        }

        .current-chat-message-own, .current-chat-message-partner {
            min-width: calc(320px - 3rem);
            max-width: calc(100vw - 3rem);
        }

        .current-chat-message-own {
            margin-left: 0.1rem;
        }

        .current-chat-message-partner {
            margin-right: 0.1rem;
        }

        .back-to-chat-partners-btn {
            display: inline-flex;
        }
    }

    .dark {
        .chat-container {
            box-shadow: none;
            background-color: $dark-gray;
        }

        .chat-list-left, .chat-window-right {
            background-color: $dark-gray;
        }

        .overlay {
            background-color: $dark-gray;
        }
    }

    
}