@use "../../style/variables.scss" as *;

// .search-bar-container {
//     position: fixed;
//     bottom: 0;
//     width: 100%;
// }
// .search-bar{
//     width: 100%;
//     padding: 4rem 8rem;
//     background-color: rgba($pink, 0.5);
// }

nav {
    position: fixed;
    right: 0;
    background-color: $white;
    border-left: dashed $dark-gray 2px;
    height: 100vh;
    min-height: 600px;
    width: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 1rem 0;
    // gap: .5rem;

    #logo {
        transform: rotate(90deg);
        margin-top: 2rem;
        font-family: $logo-font;
        font-size: 2.8rem;
        font-weight: 100;
        // color: $dark-gray;
    }

    ul {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 90vh;
        // min-height: 500px;
        align-items: center;
        // & a {
        //     padding-bottom: 4rem;
        // }
    }

    .start-nav {
        justify-content: flex-end;
    }

    ul > li {
        transform: rotate(90deg);
        text-transform: uppercase;
        letter-spacing: 0.7px;
        background-image: linear-gradient(90deg, rgba(255,233,39,1) 60%, rgba(255,233,39,0.3113620448179272) 96%);
        background-position: 0 1em;
        background-size: 0 50%;
        background-repeat: no-repeat;
        transition: background-size .5s;
        &:hover {
          background-size: 100% 50%;
        }
        &:visited {
            color: $dark-gray;
        }
        .active {
            background-image: linear-gradient(90deg, rgba(255,233,39,1) 60%, rgba(255,233,39,0.3113620448179272) 96%);;
            background-position: 0 1em;
            background-size: 100% 50%;
            background-repeat: no-repeat;
        }
        &:last-child .active, .mode .active {
            background-image: none;
        }
      
    }

    .nav-chat-notification {
        // font-family: $extra-font;
        font-weight: 700;
        position: absolute;
        top: 0;
        right: -1rem;
        display: inline-block;
        font-size: .7rem;
        height: 15px;
        width: 15px;
        background-color: $yellow;
        border-radius: 50%;
        text-align: center;
        background-image: none;
    }

    .search-btn {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 2rem;
        &:hover {
            background-image: none;
        }
    }

    .mode {
        position: static;
        font-size: 1.4rem;
        text-align: center;
        margin-bottom: 2rem;
    }

}  

.dark {
    
        nav {

            // color: $light-gray;
            background-color: $dark-gray;
            border-left: dashed $light-gray 2px;

            ul > li {
                background-image: linear-gradient(90deg, rgb(164, 121, 216) 15%, rgba(164, 121, 216, 0.2973564426) 96%);
                a {
                }
            }
            
            .active {
                background-image: linear-gradient(90deg, rgb(164, 121, 216) 60%, rgba(164, 121, 216, 0.2973564426) 96%);;
                // background-position: 0 1em;
                // background-size: 100% 50%;
                // background-repeat: no-repeat;

            }

            .nav-chat-notification {
                color: $light-gray;
                background-color: $lavender;
            }

             .mode  {
                background-image: none;
            }
            
            .mode {
                color: $light-gray;
            }
            a {
                color: $light-gray;
                border: none;
            }

        }
    
}

@media screen and (max-width: $max-screen) {
    nav {
        position: relative;
        border-left: none;
        border-bottom: dashed $dark-gray 2px;
        height: 4.5rem;
        min-height: 4rem;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // padding: 0 .5rem 0 .5rem;

        // this should just be there, when mobile nav is visible:
        // margin-bottom: 40vh;

        #logo {
            transform: rotate(0deg);
            margin: 0 0 0 1rem;
            font-size: 2rem;
        }
        
        .nav-toggle {
            font-size: 2rem;
            position: absolute;
            right: 1.05rem;
            top: 1.05rem;
        }
    
        ul {
            margin: 0;
            position: absolute;
            width: 100vw;
            top: 4.5rem;
            height: 40vh;
            min-height: 40vh;
            display: flex;
            border-bottom: dashed $dark-gray 2px;
            z-index: 3;
            background-color: lighten($lavender, 25%);
        }
    
        .start-nav {
            justify-content: flex-end;
        }
    
        ul > li {
            transform: rotate(0deg);
            text-transform: uppercase;
            background: none;
            background-image: linear-gradient(90deg, rgba(164,121,216,1) 15%, rgba(164,121,216,0.29735644257703087) 96%);
            background-position: 0 1em;
            background-size: 0 50%;
            background-repeat: no-repeat;
            transition: background-size .5s;
            &:hover {
                background-size: 100% 50%;
              }
           
            .active {
                background-image: linear-gradient(90deg, rgba(164,121,216,1) 15%, rgba(164,121,216,0.29735644257703087) 96%);
            }
            a:visited {
                color: $dark-gray;
            }
        }

        .nav-chat-notification {
            top: 0;
            right: -1rem;
            background-color: $lavender;
            color: $light-gray;
        }
    
        .mode {
            position: absolute;
            top: 1.4rem;
            right: 6rem;
            font-size: 1.4rem;
            z-index: 5;
        }

        .search-btn {
            position: absolute;
            top: 1.55rem;
            right: 9rem;
            font-size: 1.2rem;
        }

    }  

    .dark {

        nav {
            border-bottom: dashed $light-gray 2px;
            border-left: none;

            ul {
                background-color: $dark-lavender;
                border-bottom: dashed $light-gray 2px;
                li {
                    a {
                        color: $light-gray;
                    }
                }
            }
        }
}
}