@use '../../style/variables.scss' as *;

.profile-container {
    margin: 5rem auto;
    display: flex;
    width: 80%;
}

.profile-left-column {
    width: 40%;
    border-right: 2px dotted $dark-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .profile-header-username {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        h3 {
            word-wrap: break-word;
            height: 3rem;
            padding: 0 2rem;
            letter-spacing: 2px;
        }
        form {
            display: flex;
            max-width: 500px;
            padding: 0 2rem;
        }
    }
    form {
        input {
            font-family: $extra-font;
            letter-spacing: normal;
            color: $dark-gray;
            font-weight: 200;
        }
    }
}

.profile-picture-container {
    padding: 2rem;
    display: flex;
}

.profile-img {
    position: relative;
    border-radius: 50%;
    // border: 3px solid $dark-gray;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.profile-picture-update-btn {
    font-size: 1.5rem;
    color: $dark-gray;
    position: relative;
    top: 120px;
    left: -5px;

    svg {
      &:hover{
          * {
              color: $pink;
            }
        }
       
    }
}

.profile-info-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.profile-info {
    width: 100%;
    // font-family: $extra-font;
    margin-top: 1rem;
    .info-text-form {
        width: 100%;
    }
    textarea {
        border: $dark-gray 2px solid;
        width: 100%;
        height: 6rem;
        font-size: 0.9rem;
        line-height: 1.2rem;
        padding: .5rem;
        &:focus-visible {
            outline: $pink 2px solid;
            border: $pink 2px solid;
        }
    }
}

.profile-info-edit-btn {
    margin-top: 1.5rem;
}

.profile-right-column {
    width: 60%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-connect-btn-container {
    margin-bottom: 2rem;
    display: flex;
    padding: 0 2rem;
}

.profile-connect-btn {
    background-color: $dark-gray;
    color: $pink;

    // &:hover {
    // box-shadow: inset 0 0 0 2px $pink;
    // }
    &:active {
        background-color: $pink;
        color: $dark-gray;
        box-shadow: none;
    }
}

.profile-track-list {
    // width: 800px;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
}

.profile-no-tracks-yet-text {
    padding-left: 2rem;
}

.profile-track-line {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.profile-track-line-flex-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.profile-play-btn {
    font-size: 2rem;
    padding: 0 2rem;
    margin-top: 0.35rem;

   
    svg:hover {
        * {
            stroke: $pink;
        }
    }
   
}

.profile-align-container {
    width: 300px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-track-title {
    font-size: 1.1rem;
    font-family: $main-font;
    padding-right: 1rem;
}

.profile-track-duration {
    font-size: 1.1rem;
    font-family: $main-font;
}

.profile-track-edit-btn {
    font-size: 1.5rem;
}

.profile-track-delete-btn {
    font-size: 1.5rem;
    margin-top: 0.35rem;

    &:hover {
        color: $pink;     
    }
}

.profile-track-add-btn {
    padding: 0 2rem;
    margin-top: 2rem;
    font-size: 1.5rem;
    svg {
        &:hover {
            color: $turquoise;
        }
    }
}


// ------------- dark mode --------------------

.dark {

.profile-left-column {
    border-right: 2px dotted $light-gray;
}

    .profile-picture-update-btn {
        color: $light-gray;
        svg {
        &:hover{
          * {
              color: $dark-pink;
            }
        }
       
    }
    }

    .profile-info {
        textarea {
            border: $light-gray 2px solid;
            background-color: $dark-gray;
            color: $light-gray;

            &:focus-visible {
                outline: $dark-pink 2px solid;
                border: $dark-pink 2px solid;
            }
        }
    }

    .profile-info-edit-btn {
        button {
            border: $light-gray 2px solid;
        }
    }

    .profile-connect-btn {
        color: $light-gray;
        background-color: $dark-pink;
        box-shadow: none;

        // &:hover {
        //     box-shadow: inset 0 0 0 2px $pink;
        // }
        &:active {
            color: $dark-pink;
            background-color: $pink;

        }
    }

    .profile-play-btn {
        * {
            stroke: $light-gray;
            fill: $dark-gray;
        }
        &:hover {
            *{
                stroke: $dark-pink;
            }
        }
    }

    .profile-track-delete-btn {
        &:hover {
            color: $dark-pink;     
        }
    }
    
    .profile-track-add-btn {
        svg {
            &:hover {
                color: $yellow;
            }
        }
    }

    .nameForm {
        button {
            background-color: $light-gray;
            color: $dark-gray;
            border: 2px solid white;
            box-shadow: none;
        }
    }
}

@media screen and (max-width: $max-screen) {
    .profile-container {
        flex-direction: column;
        width: 100%;
        // height: 120vh;
        margin: 0;
        padding: 1rem;
    }
   
    .profile-left-column-header {
        display: flex;
        flex-direction: column;
        .profile-header-username {
            form, h3 {
                margin: 0;
                padding: 0;
            }
        }
    }
    .profile-picture-container {
        padding: 0.5rem;
        margin-bottom: 1rem;
    }


    .profile-info-container {
        padding: 0;
        margin-bottom: 1rem;
        max-width: 500px;
    }
    .profile-left-column, .profile-right-column {
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;
    }

    .profile-right-column {
        max-width: 500px;
    }

    .profile-left-column{
        h3 {
            text-align: left;
            font-size: 1.8rem;
            padding: 0;
        }
        // .profile-left-column-header {
        //     display: flex;
        //     flex-direction: row-reverse;
        //     align-items: flex-end;
        //     justify-content: center;
        // }
    }
    .profile-info {
        width: 100%;
    }

    .profile-info-text-container {
        width: 100%;
    }

    .intro-text-form {
        width: 100%;
        text-align: right;
        input[type=text] {
            height: 3rem;
            width: 100%
        } 
        button {
            display: inline-block;
        }
    }

    .profile-play-btn {
        padding: 0;
        padding-right: 1rem;
    }

    .profile-track-add-btn {
        padding: 0;
        text-align: left;
    }

    .profile-connect-btn-container {
        padding: 0;
        margin-top: 2rem;
    }

    .pic-upload-form-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        .pic-upload-form {
            position: static;
        }
    }

    .dark {
        .profile-left-column {
            border: none;
        }
    }

    .profile-no-tracks-yet-text {
        padding-left: 0;
    }
}       
 
