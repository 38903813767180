@use '../../../style/variables.scss' as *;

//main styling  
.alert-container {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: none!important;
    pointer-events: none;
}

.alert {
    position: sticky;
    margin-bottom: 1rem;
    right: 2rem;
    padding: .75rem 1.5rem ;
    width: 300px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    transform: translateX(-5%);
    pointer-events: stroke;
    cursor: pointer;
    a {
        font-size: 1.5rem;
        color: $dark-gray;
        display: flex;
        align-items: center;
    }
    a:hover {
        color: $yellow;
    }
}

.fade-in {
    animation: blendIn 2s ease-in-out;
}

.fade-out {
    animation: blendOut 2s ease-in-out;
}

//animation

@keyframes blendIn {
    from {
        opacity: 0;
        transform: translateX(0);
    }
    to {
        opacity: 1;
        transform: translateX(-5%);
    }

}

@keyframes blendOut {
    from {
        opacity: 1;
        transform: translateX(-5%);
    }
    to {
        opacity: 0;
        transform: translateX(0);
    }

}

//type styling
//error
.error {
    background-color: $pink;
}

//success
.success {
    background-color: $turquoise;
}

//info
.info {
    background-color: $blue;
}

//warning
.warning {
    background-color: $yellow;
    a:hover {
        color: $blue;
    }
}